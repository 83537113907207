<template>
  <div class="content">
    <div class="head">
      <div class="box">
        <div class="logo-box">
          <img class="logo" src="@/assets/logo2.jpg" alt="" />
          <div class="br"></div>
          <div class="phone">
            <img class="icon" src="@/assets/dianhua-F.png" alt="" />
            <!-- <div>(0532)55552938</div> -->
          </div>
        </div>

        <div class="tabs">
          <div
            class="item"
            :class="{ active: active === 0 }"
            @click="indexclick"
          >
            首页
          </div>
          <div
            class="item"
            :class="{ active: active === 1 }"
            @click="exhibitorclick"
            v-if="userInfo.isType == 2"
          >
            展商中心
          </div>
          <div
            class="item"
            :class="{ active: active === 2 }"
            @click="setupnewspaperclick"
            v-if="userInfo.isType == 1"
          >
            搭建报馆
          </div>
          <div
            class="item"
            :class="{ active: active === 3 }"
            @click="myinfoclick"
          >
            个人中心
          </div>
          <div
            class="item"
            :class="{ active: active === 4 }"
            @click="contactmy"
          >
            联系我们
          </div>
          <div
            class="item"
            :class="{ active: active === 5 }"
            @click="downloadcenter"
          >
            下载中心
          </div>
        </div>
        <span class="type" @click="login">退出登录</span>
        <!-- <span class="phone"><i class="el-icon-phone"></i>18888888888</span> -->
      </div>
    </div>
    <!-- 站位 -->
    <div style="height: 88px"></div>
    <el-dialog title="联系我们" :visible.sync="dialog" width="50%">
      <!-- <div class="dialog">(0532)55552938</div> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="dialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      dialog: false,
      userInfo: {
        isType: 1,
      },
    };
  },
  methods: {
    contactmy() {
      this.dialog = true;
    },
    login() {
      this.$router.replace("/");
    },
    indexclick() {
      this.active = 0;
      this.$router.push("/index");
    },
    exhibitorclick() {
      if (!localStorage.getItem("exhibitionId")) {
        this.$message.error("请先选择展会");
        return;
      }
      this.active = 1;
      this.$router.push("/index/exhibitor");
    },
    setupnewspaperclick() {
      if (!localStorage.getItem("exhibitionId")) {
        this.$message.error("请先选择展会");
        return;
      }
      this.active = 2;
      this.$router.push("/index/setupnewspaper");
    },
    myinfoclick() {
      this.active = 3;
      this.$router.push("/index/myinfo");
    },
    downloadcenter() {
      this.active = 5;
      this.$router.push("/index/downloadcenter");
    },
  },
  mounted() {
    if (JSON.parse(localStorage.getItem("userInfos"))) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfos"));
    }
    if (this.$router.history.current.path.indexOf("/index") !== -1)
      this.active = 0;
    if (this.$router.history.current.path.indexOf("/index/exhibitor") !== -1)
      this.active = 1;
    if (
      this.$router.history.current.path.indexOf("/index/setupnewspaper") !== -1
    )
      this.active = 2;
    if (this.$router.history.current.path.indexOf("/index/myinfo") !== -1)
      this.active = 3;
    if (
      this.$router.history.current.path.indexOf("/index/downloadcenter") !== -1
    )
      this.active = 5;
  },
};
</script>
<style lang="scss" scoped>
.content {
  position: relative;
  background-color: #e9e9e9;
  min-height: 100vh;
  overflow: hidden;
  .dialog {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .head {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 88px;
    margin: 0 auto;
    overflow: hidden;
    .box {
      margin: 0 auto;
      width: 1300px;
      height: 88px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo-box {
        display: flex;
        align-items: center;
        .logo {
          margin-top: 50px;
          width: 200px;
          // height: 280px;
          &:hover {
            cursor: pointer;
          }
        }
        .br {
          margin: 0 15px;
          width: 1px;
          height: 70px;
          background-color: #ebebeb;
        }
        .phone {
          display: flex;
          align-items: center;
          .icon {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
        }
      }

      .type {
        width: 108px;
        height: 35px;
        background-color: #ff5453;
        color: #fff;
        line-height: 35px;
        font-weight: 700;
        text-align: center;
        border-radius: 50px;
        &:hover {
          cursor: pointer;
        }
      }
      .phone {
        margin-left: 10px;
        font-size: 20px;
        .el-icon-phone {
          margin-right: 5px;
        }
      }
    }
  }
  .tabs {
    margin-left: 10px;
    height: 66px;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      margin-right: 30px;
      color: #333;
      font-weight: 700;
      font-size: 20px;
      display: flex;
      position: relative;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      color: #409eff;
      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 2px;
        background-color: #409eff;
      }
    }
  }
}
</style>
